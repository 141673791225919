import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Option } from './../model/option';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
})
export class SubmenuComponent {
  @Input()
  public menuOptions: Option;
  @Output()
  @Input()
  public itemClickEvent: EventEmitter<boolean>;

  @ViewChild('ulMenu')
  private ulMenu: ElementRef<Element>;

  private readonly iconClosed = faAngleRight;
  private readonly iconOpen = faAngleDown;

  constructor() {
    if (!this.itemClickEvent) {
      this.itemClickEvent = new EventEmitter<boolean>();
    }
  }

  public irPara() {
    if (this.menuOptions.subOptions == null) {
      this.itemClickEvent.emit(true);
    } else {
      if (this.iconMenu() === this.iconClosed) {
        this.itemClickEvent.emit(false);
      }
      const nativeElement = this.ulMenu.nativeElement;
      const parentUlElement = this.findParentUl(nativeElement);
      if (parentUlElement) {
        'd-none d-block'.split(' ').forEach((s) => {
          parentUlElement.classList.toggle(s);
        });
      }
      'd-none d-block'.split(' ').forEach((s) => {
        nativeElement.classList.toggle(s);
      });
    }
  }

  private findParentUl(nativeElement: Element) {
    let i = nativeElement;
    while (i.parentElement) {
      const elementoAnterior = i.parentElement;
      if (
        elementoAnterior.tagName === 'UL' &&
        elementoAnterior.className.indexOf('gn-menu') === -1
      ) {
        return elementoAnterior;
      }
      i = elementoAnterior;
    }
  }

  public iconMenu(): IconDefinition {
    if (this.ulMenu) {
      const nativeElement = this.ulMenu.nativeElement;
      if (nativeElement.className.indexOf('d-block') === -1) {
        return this.iconClosed;
      } else {
        return this.iconOpen;
      }
    } else {
      return this.iconClosed;
    }
  }
}
