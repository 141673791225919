import { Injectable } from '@angular/core';

@Injectable()
export class AmbienteService {
  private ambiente: Ambiente;

  public setAmbiente(nomeAmbiente: string) {
    if (nomeAmbiente === 'Desenvolvimento') {
      this.ambiente = Ambiente.DESENVOLVIMENTO;
    } else if (nomeAmbiente === 'Homologação') {
      this.ambiente = Ambiente.HOMOLOGACAO;
    } else if (nomeAmbiente === 'Produção') {
      this.ambiente = Ambiente.PRODUCAO;
    } else {
      this.ambiente = Ambiente.LOCAL;
    }
  }

  public getNome(): string {
    return this.ambiente.toString();
  }

  public isProducao(): boolean {
    return this.ambiente === Ambiente.PRODUCAO;
  }

  public isHomologacao(): boolean {
    return this.ambiente === Ambiente.HOMOLOGACAO;
  }

  public isDesenvolvimento(): boolean {
    return this.ambiente === Ambiente.DESENVOLVIMENTO;
  }

  public isLocal(): boolean {
    return this.ambiente === Ambiente.LOCAL;
  }
}

enum Ambiente {
  LOCAL = 'Local',
  DESENVOLVIMENTO = 'Desenvolvimento',
  HOMOLOGACAO = 'Homologação',
  PRODUCAO = 'Produção',
}
