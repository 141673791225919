import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: LoadingService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const nonBlocking = request.headers.get('X-RGT-NonBlocking') !== null;

    if (!nonBlocking) {
      this.totalRequests++;
      this.loadingService.setLoading(true);
    }

    return next.handle(request).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          if (!nonBlocking) {
            this.decreaseRequests();
          }
        }
      }),
      catchError((err) => {
        if (!nonBlocking) {
          this.decreaseRequests();
        }
        throw err;
      })
    );
  }

  private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loadingService.setLoading(false);
    }
  }
}

export const LoadingInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoadingInterceptor,
  multi: true,
};
