/* eslint-disable @typescript-eslint/no-magic-numbers */
export class CookieUtil {
  private static readonly NOME_ESCOLHIDO = 'RGT-NOME';
  private static readonly CPF_LOGIN = 'RGT-CPF';

  public static addNomeEscolhidoPeloCidadao(nome: string, cpf: string) {
    const date = new Date();
    date.setUTCHours(23, 59, 59, 999);
    const expires = 'expires=' + date.toUTCString();
    this.setCookie(this.NOME_ESCOLHIDO, nome, expires);
    this.setCookie(this.CPF_LOGIN, cpf, expires);
  }

  public static deleteCookieEscolhaNome() {
    const date = new Date();
    date.setTime(date.getTime() - 5 * 1000); //setando data expirada é a forma de remover
    const expires = 'expires=' + date.toUTCString();
    this.setCookie(this.NOME_ESCOLHIDO, '', expires);
    this.setCookie(this.CPF_LOGIN, '', expires);
  }

  public static getNomeEscolhido(): string {
    return this.getCookie(this.NOME_ESCOLHIDO);
  }

  public static getCpfUltimaEscolha(): string {
    return this.getCookie(this.CPF_LOGIN);
  }

  public static usarNomeSocial(): boolean {
    return this.getNomeEscolhido() === 'social';
  }

  private static getCookie(cName: string): string {
    const chaveValor = document.cookie
      .split(';')
      .filter((x) => x.indexOf(cName) !== -1)[0];
    if (!chaveValor) {
      return undefined;
    }
    return chaveValor.replace(cName + '=', '').trim();
  }

  private static setCookie(cName, cValue, expires) {
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
  }
}
