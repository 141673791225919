import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { DataService } from '@app/core/data.service';
import { User } from '@app/core/openid/user.model';
import { CookieUtil } from '@app/shared/cookie.util';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private isLogged$: BehaviorSubject<boolean | null>;
  private isLogged: Observable<boolean>;
  private user$: User;
  private userUrl = 'publico/userinfo';
  private loginUrl = environment.loginUrl;
  private logoutUrl = '/svr/rest/logout';

  constructor(
    private dataService: DataService,
    private location: Location,
    private injector: Injector,
    private permissionsService: NgxPermissionsService
  ) {
    this.isLogged$ = new BehaviorSubject<boolean | null>(null);
    this.isLogged = this.isLogged$
      .asObservable()
      .pipe(filter((state) => state !== null));
  }

  public login() {
    window.location.href = this.loginUrl;
  }

  public logout() {
    this.dataService.post<LogoutData>('logout', null).subscribe((response) => {
      document.cookie =
        'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      CookieUtil.deleteCookieEscolhaNome();
      window.location.href = response.body.oauth2LogoutUrl;
    });
  }

  public getAuthState(): boolean {
    return this.isLogged$.getValue();
  }

  public get user() {
    return this.user$;
  }

  private setAuthState(logged: boolean) {
    this.isLogged$.next(logged);
  }

  public checkAuthState(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.dataService.get<User>(this.userUrl).subscribe(
        (response) => {
          if (response.username) {
            this.user$ = response;
            this.setAuthState(true);
            this.loadPermissions();
            resolve(true);
          } else {
            this.setAuthState(false);
            resolve(true);
          }
        },
        () => {
          this.setAuthState(false);
          resolve(true);
        }
      );
    });
  }

  private loadPermissions() {
    this.permissionsService.loadPermissions(this.user.roles);
  }

  public isPerfilAtendimento(): boolean {
    return this.user.roles.includes('ATENDIMENTO');
  }

  public isPerfilCambio(): boolean {
    return this.user.roles.includes('CONSULTAR_CAMBIO_BCB');
  }
}

interface LogoutData {
  oauth2LogoutUrl: string;
}
