import { APP_INITIALIZER, Injectable } from '@angular/core';
import { LoginService } from '@app/core/openid/login.service';

const runLoginService = (init: LoginInitializerService) => () => init.run();

@Injectable()
export class LoginInitializerService {
  constructor(private loginService: LoginService) {}

  public run() {
    return this.loginService.checkAuthState();
  }
}

export const LoginInitializerProvider = [
  LoginInitializerService,
  {
    provide: APP_INITIALIZER,
    deps: [LoginInitializerService],
    multi: true,
    useFactory: runLoginService,
  },
];
