// Esses valores são substituídos durante o build
export const version = {
  commit: '27a22f43',
  buildDate: '20240823105543134',
  commitDate: '20240823132922',
};

export class BuildDateFormatter {
  public static formatBuildDate(jenkinsDate: string): string {
    return jenkinsDate.replace(
      /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*/,
      '$3/$2/$1 $4:$5'
    );
  }
}
