import { Component } from '@angular/core';
import { LoginService } from '@app/core/openid/login.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.page.html',
})
export class AccessDeniedPage {
  constructor(private loginService: LoginService) {}

  public logout() {
    this.loginService.logout();
  }
}
