import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DataService {
  private baseUrl = '/registrato/rest/';

  constructor(private httpClient: HttpClient) {}

  public get<T>(url: string, params = {}, returnError = false): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    let options: { headers?: HttpHeaders; params: {} };
    if (returnError) {
      let headers = new HttpHeaders();
      headers = headers.set('X-RGT-ReturnError', 'true');
      options = { headers: headers, params };
    } else {
      options = { params };
    }

    return this.httpClient.get<T>(this.baseUrl + url, options);
  }

  public getNonBlocking<T>(url: string, params = {}): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set('X-RGT-NonBlocking', 'true');
    return this.httpClient.get<T>(this.baseUrl + url, {
      headers: headers,
      params,
    });
  }

  public getArquivo(url: string, params = {}): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.httpClient.get(this.baseUrl + url, {
      headers: headers,
      responseType: 'blob',
      params,
    });
  }

  public getArquivoConsultaPublica(
    url: string,
    params = {}
  ): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    headers = headers.set('X-RGT-ReturnError', 'true');
    return this.httpClient.get(this.baseUrl + url, {
      headers: headers,
      responseType: 'blob',
      observe: 'response',
      params,
    });
  }

  public post<T>(
    url: string,
    body: any,
    escolha: string = null
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    if (escolha) {
      headers = headers.set('RGT-NOME', escolha);
    }
    return this.httpClient.post<T>(this.baseUrl + url, body, {
      headers: headers,
      observe: 'response',
    });
  }

  public postForBody<T>(
    url: string,
    body: any,
    escolha: string = null
  ): Observable<T> {
    return this.post<T>(url, body, escolha).pipe(map((hr) => hr.body));
  }

  public put<T>(url: string, body: any): Observable<HttpResponse<T>> {
    return this.httpClient.put<T>(this.baseUrl + url, body, {
      observe: 'response',
    });
  }

  public delete<T>(url: string): Observable<HttpResponse<T>> {
    return this.httpClient.delete<T>(this.baseUrl + url, {
      observe: 'response',
    });
  }
}
