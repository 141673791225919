import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  private initialized: boolean = false;

  constructor(private gtm: GoogleTagManagerService) {}

  public init(): void {
    void this.gtm.addGtmToDom();
    this.initialized = true;
  }

  public event(name: string, args: object): void {
    if (!this.initialized) {
      return;
    }

    const tag = {
      event: name,
      ...args,
    };
    void this.gtm.pushTag(tag);
  }
}
