<div class="panel">
  <div class="panel-heading">
    <h2 class="panel-title" tabindex="0">Sessão expirada</h2>
  </div>

  <div class="panel-body col-12">
    <section>
      <div class="alert alert-danger">
        <p>O tempo de validade de sua sessão expirou.</p>

        <p>
          <button class="btn btn-primary" (click)="login()">
            Renovar sessão
          </button>
        </p>
      </div>
    </section>
  </div>
</div>
