import { Component, Input } from '@angular/core';
import { Cabecalho } from '@app/core/cabecalho/cabecalho';
import { CabecalhoService } from '@app/core/cabecalho/cabecalho.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent {
  @Input() public version: string;

  public cabecalho: Observable<Cabecalho>;

  constructor(cabecalhoService: CabecalhoService) {
    this.cabecalho = cabecalhoService.getCabecalho();
  }
}
