import { Component } from '@angular/core';
import { LoginService } from '@app/core/openid/login.service';

@Component({
  selector: 'app-sessao-expirada',
  templateUrl: './sessao-expirada.page.html',
})
export class SessaoExpiradaPage {
  constructor(private loginService: LoginService) {}

  public login() {
    this.loginService.login();
  }
}
