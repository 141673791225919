import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CabecalhoService } from '@app/core/cabecalho/cabecalho.service';
import { ErrorToken } from '@app/core/errorpage/error.model';
import { DateUtil } from '@app/core/util/date.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-error-page',
  templateUrl: './error.page.html',
})
export class ErrorPage implements OnInit {
  public dataHora: string;
  public token: string;

  constructor(
    private route: ActivatedRoute,
    cabecalhoService: CabecalhoService
  ) {
    cabecalhoService.setCabecalho(
      'Erro inesperado',
      'Não foi possível concluir sua solicitação'
    );
    this.dataHora = DateUtil.format(new Date());
  }

  public ngOnInit(): void {
    const errorToken$: Observable<ErrorToken> = this.route.paramMap.pipe(
      map(() => window.history.state as ErrorToken)
    );
    errorToken$.pipe(untilDestroyed(this)).subscribe((error) => {
      if (error.dataHora) {
        this.dataHora = error.dataHora;
      }
      if (error.token) {
        this.token = error.token;
      }
    });
  }
}
