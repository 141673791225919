<nav #navMenu class="gn-menu-wrapper sidebar">
  <div class="gn-scroller">
    <ul class="gn-menu">
      <ng-container *ngFor="let menu of menus">
        <li
          class="gn-menu-border-bottom"
          *ngxPermissionsOnly="menu.permissions">
          <app-submenu
            [menuOptions]="menu"
            (itemClickEvent)="clickItemMenu($event)"></app-submenu>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="version-footer">
    <span class="float-left">Versão:</span>
    <a routerLink="/publico/versao" (click)="clickItemMenu(true)">
      {{ versao }}
    </a>
  </div>
</nav>
