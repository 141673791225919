<!-- NAVBAR TOP -->
<div class="top-bar">
  <div class="top-bar-logo">
    <a title="Bem vindo ao sistema Registrato" routerLink="/">
      <span class="link-text">Logo do Banco Central</span>
    </a>
  </div>
  <div class="top-bar-links">
    <ul>
      <!-- Links conteúdo-->
      <li>
        <span
          class="top-bar-links-ambient"
          (keydown.enter)="clickEventTheme()"
          (click)="clickEventTheme()">
          Ambiente de {{ labelAmbiente }}
        </span>
      </li>
      <li>
        <div
          class="top-bar-links-dropdown"
          ngbDropdown
          placement="bottom-right">
          <button ngbDropdownToggle type="button" class="dropdown-toggle">
            <fa-icon [icon]="iconConteudos"></fa-icon>
            <span class="link-text">Link para conteúdos</span>
          </button>
          <ul
            ngbDropdownMenu
            class="dropdown-menu-right dropdown-menu"
            role="menu">
            <li role="menuitem">
              <a
                tabindex="0"
                class="dropdown-item"
                (keydown.enter)="setFoco('link-menu')"
                (click)="setFoco('link-menu')">
                <fa-icon [icon]="iconIrMenu"></fa-icon>
                &nbsp;
                <span>Ir para o menu</span>
              </a>
            </li>
            <li role="menuitem">
              <a
                tabindex="0"
                class="dropdown-item"
                (keydown.enter)="setFoco('link-conteudo')"
                (click)="setFoco('link-conteudo')">
                <fa-icon [icon]="iconIrConteudo"></fa-icon>
                &nbsp;
                <span>Ir para o conteúdo</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <!--Contraste -->
      <li>
        <a
          (keydown.enter)="clickEventContraste()"
          (click)="clickEventContraste()"
          tabindex="0"
          class="top-bar-links-contrast"
          aria-label="Alto contraste">
          <fa-icon [icon]="iconContraste"></fa-icon>
          <span class="link-text">Alto contraste</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- END NAVBAR TOP -->

<div class="clearfix"></div>

<!--gn-open-all gn-open-part-->

<div class="navbar-fixed-top">
  <ul id="gn-menu" class="gn-menu-main">
    <li>
      <div class="navbar-user user-account">
        <div class="form-group">
          <span class="control-label user-nome">
            <strong id="nomeUsuarioLogado">{{ username }}</strong>
          </span>
          <br />
        </div>
      </div>
    </li>
    <li class="gn-trigger">
      <a #menu class="gn-icon d-md-none">
        <fa-icon [icon]="iconMenu"></fa-icon>
      </a>
      <app-menu [menu]="menu" [menus]="menus" [versao]="versao"></app-menu>
    </li>
    <li class="ml-3">
      <span class="titulo-app">Registrato</span>
    </li>
    <li class="right-side">
      <div
        *ngIf="temUsuario()"
        ngbDropdown
        class="nav navbar-links menu-usuario nav-item w-100"
        style="line-height: 37px"
        placement="bottom-right">
        <a class="nav-link" ngbDropdownToggle>
          <fa-icon [icon]="iconUsuario"></fa-icon>
        </a>
        <div ngbDropdownMenu class="dropdown-menu-right menu-tamanho-itens">
          <button class="btn btn-link" routerLink="/publico/userinfo">
            <fa-icon class="float-left mr-2" [icon]="iconInfoUsuario"></fa-icon>
            <span class="float-left">Informações do usuário</span>
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-link" (click)="logout()">
            <fa-icon class="float-left mr-2" [icon]="iconLogout"></fa-icon>
            <span class="float-left">Sair do sistema</span>
          </button>
        </div>
      </div>
    </li>
    <li class="right-side tamanho-horas">
      <div class="barra-divisoria-direita navbar-user">
        <span class="navbar-links-hora">{{ hora | date: 'medium' }}</span>
      </div>
    </li>
  </ul>
</div>
