import { Injectable } from '@angular/core';
import { Violacao } from '@app/core/feedback/feedback-erros.model';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class FeedbackService {
  constructor(private toastr: ToastrService) {}

  public notificarSucesso(msg: string) {
    this.toastr.success(msg);
  }

  public notificarErros(erros: Violacao[]) {
    const msg = erros.map((e) => e.mensagem).join('<br/>');
    this.toastr.error(msg, '', { enableHtml: true });
  }
}
