import { Component } from '@angular/core';
import { BuildDateFormatter, version } from '@app/app.version';
import { AmbienteService } from '@app/core/ambiente/ambiente.service';
import { GoogleAnalyticsService } from '@app/core/google-analytics/google-analytics.service';
import { createMenuOption } from './structure/menu/menu-options';
import { Option } from './structure/menu/model/option';

@Component({
  selector: 'app-root',
  styles: [],
  templateUrl: './app.component.html',
})
export class AppComponent {
  public options: Option[];

  private statusContrast: boolean = false;

  constructor(
    private ambiente: AmbienteService,
    private ga: GoogleAnalyticsService
  ) {
    this.createMenu();
    this.ga.init();
  }

  public createMenu() {
    this.options = createMenuOption();
  }

  public changeContrast(contrastOn: boolean) {
    this.statusContrast = contrastOn;
  }

  public getMainClasses() {
    return {
      'color-theme': true,
      'local-theme': this.ambiente.isLocal(),
      'desenv-theme': this.ambiente.isDesenvolvimento(),
      'homologa-theme': this.ambiente.isHomologacao(),
      'prod-theme': this.ambiente.isProducao(),
      'com-contrast-theme': this.statusContrast,
      'sem-contrast-theme': !this.statusContrast,
    };
  }

  public get labelAmbiente() {
    return this.ambiente.getNome();
  }

  public get versaoSistema() {
    return BuildDateFormatter.formatBuildDate(version.commitDate);
  }
}
