import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  private channel$ = new Subject<boolean>();
  private channel = this.channel$.asObservable();

  public setLoading(isLoading: boolean) {
    this.channel$.next(isLoading);
  }

  public getChannel(): Observable<boolean> {
    return this.channel;
  }
}
