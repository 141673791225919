import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from '@app/core/openid/login.service';
import { CookieUtil } from '@app/shared/cookie.util';
import { Option } from '@app/structure/menu/model/option';
import {
  faAdjust,
  faBars,
  faCogs,
  faDoorOpen,
  faTable,
  faThList,
  faUser,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  private static readonly INTERVALO_UPDATE_HORARIO = 1000;

  public hora = new Date();

  @Input()
  public versao: string;
  @Input()
  public menus: Option[];
  @Input()
  public labelAmbiente;

  public readonly iconMenu = faBars;
  public readonly iconConteudos = faThList;
  public readonly iconIrMenu = this.iconMenu;
  public readonly iconIrConteudo = faTable;
  public readonly iconContraste = faAdjust;
  public readonly iconSistema = faCogs;
  public readonly iconUsuario = faUser;
  public readonly iconInfoUsuario = faUserCog;
  public readonly iconLogout = faDoorOpen;

  @Output()
  private contrastChange = new EventEmitter<boolean>();
  @Output()
  private themeChange = new EventEmitter<void>();

  private statusContrast: boolean = false;

  constructor(private loginService: LoginService) {}

  public ngOnInit() {
    const larguraJanela = window.innerWidth;
    const largura = 1025;
    // if mobile screen, default closed, show right arrow icon
    if (larguraJanela <= largura) {
      document.querySelector('body').classList.toggle('sidebar-minified');
    }

    setInterval(
      () => (this.hora = new Date()),
      HeaderComponent.INTERVALO_UPDATE_HORARIO
    );
  }

  public clickEventContraste() {
    this.statusContrast = !this.statusContrast;
    this.contrastChange.emit(this.statusContrast);
  }

  public clickEventTheme() {
    this.themeChange.emit();
  }

  public setFoco(id: string) {
    const el = document.getElementById(id);
    if (el) {
      el.focus();
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `[header.component]: ERRO, não existe ${id} para a página em questão`
      );
    }
  }

  public temUsuario(): boolean {
    return !!this.loginService.user;
  }

  public get username() {
    const user = this.loginService.user;
    if (user) {
      if (user.usuarioBacen) {
        return user.username.toUpperCase();
      } else if (CookieUtil.getNomeEscolhido() === 'social') {
        return user.nomeSocial;
      } else if (CookieUtil.getNomeEscolhido() === 'civil') {
        return user.nomeCompleto;
      } else {
        if (user.nomeSocial !== null) {
          return '';
        }
        return user.nomeCompleto;
      }
    }
  }

  public logout() {
    this.loginService.logout();
  }
}
