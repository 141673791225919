import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccessDeniedLuPage } from '@app/core/access-denied-lu/access-denied-lu.page';
import { AccessDeniedPage } from '@app/core/access-denied/access-denied.page';
import { AmbienteInitializerProvider } from '@app/core/ambiente/ambiente.initializer';
import { AmbienteService } from '@app/core/ambiente/ambiente.service';
import { CabecalhoService } from '@app/core/cabecalho/cabecalho.service';
import { DataService } from '@app/core/data.service';
import { HttpErrorInterceptorProvider } from '@app/core/erro/http-error.interceptor';
import { ErrorPage } from '@app/core/errorpage/error.page';
import { FeedbackService } from '@app/core/feedback/feedback.service';
import { LoadingInterceptorProvider } from '@app/core/loading/loading.interceptor';
import { LoadingService } from '@app/core/loading/loading.service';
import { NativeWindow } from '@app/core/native/window.native.component';
import { NotFoundPage } from '@app/core/not-found/not-found.page';
import { LoginInitializerProvider } from '@app/core/openid/login.initializer';
import { SessaoExpiradaPage } from '@app/core/sessao-expirada/sessao-expirada.page';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.ERROR,
    }),
  ],
  declarations: [
    AccessDeniedPage,
    AccessDeniedLuPage,
    ErrorPage,
    NotFoundPage,
    SessaoExpiradaPage,
  ],
  providers: [
    ...AmbienteInitializerProvider,
    ...LoginInitializerProvider,

    HttpErrorInterceptorProvider,
    LoadingInterceptorProvider,

    AmbienteService,
    DataService,
    LoadingService,
    CabecalhoService,
    FeedbackService,
    GoogleAnalyticsService,
    NativeWindow,
  ],
})
export class CoreModule {}
