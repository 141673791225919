<div class="gn-menu-label">
  <fa-icon
    [icon]="menuOptions.icon"
    class="icon-principal float-left"
    [fixedWidth]="true"></fa-icon>
  <ng-container
    *ngIf="menuOptions.externalLink; then externo; else interno"></ng-container>

  <ng-template #interno>
    <a
      class="gn-icon gn-item"
      title="{{ menuOptions.title }}"
      [routerLink]="menuOptions.link"
      (click)="irPara()">
      {{ menuOptions.label }}
      <fa-icon
        class="icon-principal float-right"
        *ngIf="menuOptions.subOptions !== null"
        [icon]="iconMenu()"></fa-icon>
    </a>
  </ng-template>

  <ng-template #externo>
    <a
      class="gn-icon gn-item"
      title="{{ menuOptions.title }}"
      [href]="menuOptions.externalLink"
      [target]="menuOptions.externalTarget"
      (click)="irPara()">
      {{ menuOptions.label }}
    </a>
  </ng-template>
</div>

<div class="">
  <ul
    #ulMenu
    *ngIf="menuOptions.subOptions !== null"
    class="d-none gn-submenu-background">
    <ng-container *ngFor="let submenu of menuOptions.subOptions">
      <li class="gn-menu-border-top" *ngxPermissionsOnly="submenu.permissions">
        <app-submenu
          [menuOptions]="submenu"
          [itemClickEvent]="itemClickEvent"></app-submenu>
      </li>
    </ng-container>
  </ul>
</div>
