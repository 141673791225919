<div class="panel">
  <div class="panel-heading">
    <h2 class="panel-title" tabindex="0">Página não encontrada</h2>
  </div>

  <div class="panel-body col-12">
    <section>
      <div class="alert alert-danger">
        <p>A página que você tentou acessar não existe.</p>

        <br />
        <br />

        <p>
          <a routerLink="/">Clique aqui para voltar à página inicial</a>
          .
        </p>
      </div>
    </section>
  </div>
</div>
