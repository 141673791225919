<div class="panel">
  <div class="panel-heading">
    <h2 class="panel-title" tabindex="0">Acesso negado</h2>
  </div>

  <div class="panel-body col-12">
    <section>
      <div class="alert alert-danger">
        <p>Você não tem permissão para acessar essa funcionalidade.</p>

        <p>
          <a routerLink="/publico/userinfo">
            Transações habilitadas para o usuário.
          </a>
        </p>

        <p>
          <button class="btn btn-primary" (click)="logout()">
            Sair do sistema
          </button>
        </p>
      </div>
    </section>
  </div>
</div>
