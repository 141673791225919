<div class="panel">
  <div class="panel-heading">
    <h2 class="panel-title" tabindex="0">Erro interno</h2>
  </div>

  <div class="panel-body col-12">
    <section>
      <div class="alert alert-danger">
        <p>Ocorreu um erro interno no sistema.</p>

        <p>
          Se o problema persistir, entre em contato com a equipe de suporte pelo
          telefone 145.
        </p>

        <p>Sua ajuda é muito importante para a correção do problema.</p>

        <p>Favor informar, se possível:</p>
        <ul>
          <li>A data/hora: {{dataHora}}</li>
          <li *ngIf="token">Código do erro: {{token}}</li>
          <li>A operação que tentava realizar.</li>
          <li>Os dados usados na operação.</li>
          <li>Notou situações em que o problema não ocorre? Quais são?</li>
        </ul>

        <br />
        <br />

        <p>
          <a routerLink="/">Clique aqui para voltar à página inicial</a>
          .
        </p>
      </div>
    </section>
  </div>
</div>
