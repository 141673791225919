import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/core/loading/loading.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';

@UntilDestroy()
@Component({
  selector: 'app-loading',
  template:
    '<ngx-spinner type="ball-newton-cradle" size="medium" class="full-screen-spinner"></ngx-spinner>',
  styles: [
    '.full-screen-spinner ::ng-deep .overlay { position: fixed !important; }',
  ],
})
export class LoadingComponent implements OnInit {
  private static readonly DELAY_EXIBICAO = 150;

  private timeoutId: number;

  constructor(
    private loadingService: LoadingService,
    private spinner: NgxSpinnerService
  ) {}

  public ngOnInit(): void {
    this.loadingService
      .getChannel()
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => {
        if (isLoading) {
          this.showSpinner();
        } else {
          this.hideSpinner();
        }
      });
  }

  private showSpinner() {
    if (this.timeoutId) {
      return;
    }
    this.timeoutId = window.setTimeout(() => {
      void this.spinner.show();
      this.timeoutId = null;
    }, LoadingComponent.DELAY_EXIBICAO);
  }

  private hideSpinner() {
    void this.spinner.hide();
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}
