import { Injectable } from '@angular/core';
import { PtDateParserFormatter } from '@app/shared/data-input/data-input.parser-formatter';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class PtStringDateAdapter extends NgbDateAdapter<string> {
  private formatter = new PtDateParserFormatter();

  public fromModel(value: string): NgbDateStruct {
    return this.formatter.parse(value);
  }

  public toModel(date: NgbDateStruct): string {
    return this.formatter.format(date);
  }
}
