import { Injectable } from '@angular/core';
import { DataService } from '@app/core/data.service';
import { Captcha } from '@app/shared/captcha/captcha.model';
import { Observable } from 'rxjs';

@Injectable()
export class CaptchaService {
  private url = 'publico/captcha';

  constructor(private dataService: DataService) {}

  public solicitarCaptcha(): Observable<Captcha> {
    return this.dataService.get<Captcha>(`${this.url}`);
  }
}
