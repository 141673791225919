import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { AmbienteService } from '@app/core/ambiente/ambiente.service';

@Injectable()
export class AmbienteInitializer {
  constructor(
    private httpClient: HttpClient,
    private ambienteService: AmbienteService
  ) {}

  public load(): Promise<any> {
    const promise = this.httpClient
      .get('/registrato/rest/publico/ambiente', { responseType: 'text' })
      .toPromise()
      .then((ambiente) => {
        this.ambienteService.setAmbiente(ambiente);
        return ambiente;
      });

    return promise;
  }
}

const ambienteInitializerFactory = (
  ambienteInitializer: AmbienteInitializer
) => {
  return () => ambienteInitializer.load();
};

export const AmbienteInitializerProvider = [
  AmbienteInitializer,
  {
    provide: APP_INITIALIZER,
    useFactory: ambienteInitializerFactory,
    deps: [AmbienteInitializer],
    multi: true,
  },
];
