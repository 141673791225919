export class DateUtil {
  private static readonly PARTE_DIA = 0;
  private static readonly PARTE_MES = 1;
  private static readonly PARTE_ANO = 2;

  public static format(now: Date) {
    return now.toLocaleString('pt-BR');
  }

  public static converterParaForm(dt: Date) {
    const dtIso = dt.toISOString().split('T')[0];
    const parts = dtIso.split('-');
    return (
      parts[this.PARTE_ANO] +
      '/' +
      parts[this.PARTE_MES] +
      '/' +
      parts[this.PARTE_DIA]
    );
  }
}
