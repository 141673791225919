import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class PtDateParserFormatter extends NgbDateParserFormatter {
  private static readonly PARTE_DIA = 0;
  private static readonly PARTE_MES = 1;
  private static readonly PARTE_ANO = 2;
  private static readonly TAMANHO_DIA = 2;
  private static readonly TAMANHO_MES = 2;
  private static readonly TAMANHO_ANO = 4;

  public parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    const parts: string[] = value.split('/');
    return {
      year: Number(parts[PtDateParserFormatter.PARTE_ANO]),
      month: Number(parts[PtDateParserFormatter.PARTE_MES]),
      day: Number(parts[PtDateParserFormatter.PARTE_DIA]),
    };
  }

  public format(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }

    return (
      this.pad(date.day, PtDateParserFormatter.TAMANHO_DIA) +
      '/' +
      this.pad(date.month, PtDateParserFormatter.TAMANHO_MES) +
      '/' +
      this.padYear(date.year, PtDateParserFormatter.TAMANHO_ANO)
    );
  }

  private pad(value: number, digits: number): string {
    return ('00' + value).slice(-digits);
  }

  private padYear(value: number, digits: number): string {
    return ('20' + value).slice(-digits);
  }
}
