import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataTableDirective } from '@app/shared/datatable/DataTable';
import { DefaultSorterComponent } from '@app/shared/datatable/DefaultSorter';
import { PaginatorComponent } from '@app/shared/datatable/Paginator';
import { BootstrapPaginatorComponent } from '@app/shared/datatable/BootstrapPaginator';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [
    DataTableDirective,
    DefaultSorterComponent,
    PaginatorComponent,
    BootstrapPaginatorComponent,
  ],
  exports: [
    DataTableDirective,
    DefaultSorterComponent,
    PaginatorComponent,
    BootstrapPaginatorComponent,
  ],
})
export class DataTableModule {}
