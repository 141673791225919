<!-- CONTENT -->
<main class="main">
  <!-- MAIN CONTENT -->
  <div class="main-content">
    <div class="content-heading clearfix d-none d-lg-block">
      <div class="heading-left">
        <h1 id="link-conteudo" tabindex="0" class="page-title">
          {{ (cabecalho | async)?.titulo }}
        </h1>
        <p tabindex="0" class="page-subtitle">
          {{ (cabecalho | async)?.descricao }}
        </p>
      </div>
      <div class="heading-right">
        <breadcrumb></breadcrumb>
      </div>
    </div>
    <!-- Coloca um espaço quando o header acima não é exibido -->
    <div class="d-lg-none mb-3"></div>

    <router-outlet></router-outlet>
  </div>
  <!-- END MAIN CONTENT -->
</main>
<!-- END CONTENT -->
<div class="clearfix"></div>
