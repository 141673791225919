import { Injectable } from '@angular/core';

@Injectable()
export class NativeWindow {
  public sendToDownload(nomeArquivo: string, data: Blob) {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.setAttribute('href', window.URL.createObjectURL(data));
    downloadLink.setAttribute('download', nomeArquivo);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
