import { Injectable } from '@angular/core';
import { Cabecalho } from '@app/core/cabecalho/cabecalho';
import { Subject } from 'rxjs';

@Injectable()
export class CabecalhoService {
  private cabecalho: Subject<Cabecalho> = new Subject<Cabecalho>();

  public getCabecalho(): Subject<Cabecalho> {
    return this.cabecalho;
  }

  public setCabecalho(titulo: string, descricao: string) {
    this.cabecalho.next({ titulo: titulo, descricao: descricao });
  }
}
