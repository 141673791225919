import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { NavCollapeDirectives } from '@app/structure/directives/nav-collape.directive';
import { SideBarToggleDirectives } from '@app/structure/directives/sidebar-toggle.directive';
import { HeaderComponent } from '@app/structure/header/header.component';
import { LoadingComponent } from '@app/structure/loading/loading.component';
import { MainComponent } from '@app/structure/main/main.component';
import { MenuComponent } from '@app/structure/menu/menu.component';
import { NgBreadcrumbModule } from 'ng-breadcrumb';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SubmenuComponent } from '@app/structure/menu/submenu/submenu.component';

@NgModule({
  imports: [
    RouterModule,

    NgBreadcrumbModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forChild(),

    SharedModule,
  ],
  declarations: [
    HeaderComponent,
    MenuComponent,
    SubmenuComponent,
    LoadingComponent,
    MainComponent,
    NavCollapeDirectives,
    SideBarToggleDirectives,
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    SubmenuComponent,
    LoadingComponent,
    MainComponent,
    NgxPermissionsModule,
  ],
})
export class StructureModule {}
