import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MobileService } from '@app/core/util/mobile.service';
import { Option } from './model/option';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  @Input()
  public versao: string;
  @Input()
  public menus: Option[];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('menu')
  public nativeMenu: HTMLElement;
  @ViewChild('navMenu', { static: true })
  public navMenu: ElementRef<Element>;

  public ngOnInit(): void {
    if (MobileService.isMobile()) {
      this.adicionarMecanicaMobile();
    } else {
      this.navMenu.nativeElement.classList.add('gn-open-all');
    }
  }

  private adicionarMecanicaMobile() {
    const nativeNavMenu = this.navMenu.nativeElement;
    // Abrir parcialmente o menu
    this.nativeMenu.onclick = () => {
      'gn-open-all'.split(' ').forEach((s) => {
        if (nativeNavMenu.className.indexOf('gn-menu-wrapper') !== -1) {
          nativeNavMenu.classList.toggle(s);
        }
      });
    };

    this.esconderMenuMobile();
  }

  private esconderMenuMobile() {
    const tempoEspera = 1000;
    const nativeNavMenu = this.navMenu.nativeElement;
    // Fechar completamente o menu ao acessar o container
    document.querySelectorAll('.main-content').forEach((content) => {
      content.addEventListener('mouseover', () => {
        if (
          nativeNavMenu.className.indexOf('gn-menu-wrapper') !== -1 &&
          nativeNavMenu.className.indexOf('gn-open-all') !== -1
        ) {
          setTimeout(() => {
            this.esconder(true);
          }, tempoEspera);
        }
      });
    });
  }

  private esconder(tudo: boolean) {
    const nativeNavMenu = this.navMenu.nativeElement;
    if (tudo && MobileService.isMobile()) {
      nativeNavMenu.className = nativeNavMenu.className.replace(
        'gn-open-all',
        ''
      );
    }
    nativeNavMenu.querySelectorAll('.d-block').forEach((filho) => {
      filho.className = filho.className.replace('d-block', 'd-none');
    });
  }

  public clickItemMenu(tudo: boolean) {
    this.esconder(tudo);
  }
}
