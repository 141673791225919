import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedLuPage } from '@app/core/access-denied-lu/access-denied-lu.page';
import { AccessDeniedPage } from '@app/core/access-denied/access-denied.page';
import { ErrorPage } from '@app/core/errorpage/error.page';
import { NotFoundPage } from '@app/core/not-found/not-found.page';
import { LoginGuard } from '@app/core/openid/login.guard';
import { SessaoExpiradaPage } from '@app/core/sessao-expirada/sessao-expirada.page';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'relatorios',
  },
  {
    path: 'publico',
    loadChildren: () =>
      import('./publico/publico.module').then((m) => m.PublicoModule),
  },
  {
    path: 'relatorios',
    loadChildren: () =>
      import('./relatorios/relatorios.module').then((m) => m.RelatoriosModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'gestao',
    loadChildren: () =>
      import('./gestao/gestao.module').then((m) => m.GestaoModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'access-denied',
    component: AccessDeniedPage,
  },
  {
    path: 'access-denied-lu',
    component: AccessDeniedLuPage,
  },
  {
    path: 'sessao-expirada',
    component: SessaoExpiradaPage,
  },
  {
    path: 'erro',
    component: ErrorPage,
  },
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
