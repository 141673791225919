import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { StructureModule } from '@app/structure/structure.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),

    GoogleTagManagerModule.forRoot({ id: environment.googleTagManagerCode }),

    CoreModule,
    StructureModule,
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
export class AppModule {}
