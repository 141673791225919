import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/core/openid/login.service';

@Component({
  selector: 'app-access-denied-lu',
  templateUrl: './access-denied-lu.page.html',
})
export class AccessDeniedLuPage implements OnInit {
  public isAcessoBronze: boolean = false;
  public isColaborador: boolean = false;

  constructor(private loginService: LoginService) {}

  public ngOnInit() {
    this.isAcessoBronze = this.temUsuarioNivel1();
    this.isColaborador = this.usuarioColaborador();
  }
  public temUsuarioNivel1(): boolean {
    return !!this.loginService.user.roles.includes('LU_NIVEL_1');
  }
  public usuarioColaborador(): boolean {
    const first = this.loginService.user.representacoes.find((obj) => {
      return obj.atuacao === 'COLABORADOR';
    });
    return first != undefined;
  }
}
