import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService } from '@app/core/feedback/feedback.service';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AresErro } from '@app/core/feedback/feedback-erros.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private feedbackService: FeedbackService,
    private injector: Injector
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const ignoreErrorHandling =
      request.headers.get('X-RGT-IgnoreErrorHandling') !== null;
    const returnError = request.headers.get('X-RGT-ReturnError') !== null;

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleHttpError(error, ignoreErrorHandling, returnError)
        )
      );
  }

  private handleHttpError(
    error: HttpErrorResponse,
    ignoreErrorHandling: boolean,
    returnError: boolean
  ) {
    const logger = this.injector.get(NGXLogger);

    if (error.error instanceof ErrorEvent) {
      // Client/Network error
      logger.log(
        `Erro: ${
          navigator.onLine ? error.error.message : 'Falha na conexão de rede!'
        }`
      );
      return EMPTY;
    } else {
      // Server error
      if (ignoreErrorHandling) {
        return throwError(error);
      }

      if (error.status === HttpStatusCode.Unauthorized) {
        return this.handleErroUnauthorized();
      }
      if (error.status === HttpStatusCode.Forbidden) {
        return this.handleErroAccessDenied();
      }
      if (error.status === HttpStatusCode.NotFound) {
        return this.handleErroNotFound();
      }
      if (error.status === HttpStatusCode.GatewayTimeout) {
        return this.handleGatewayTimeout(logger);
      }
      if (error.status === HttpStatusCode.InternalServerError) {
        return this.handleErroInesperado(logger, error);
      }

      if (error.status === HttpStatusCode.PreconditionFailed) {
        this.handleErroNegocio(error.error);
        return returnError ? throwError(error) : EMPTY;
      }

      return throwError(error);
    }
  }

  private handleErroNegocio(erro: any) {
    if (erro instanceof Blob) {
      void erro.text().then((json) => {
        const msgErro = JSON.parse(json) as AresErro;
        this.feedbackService.notificarErros(msgErro.violacoes);
      });
    } else {
      this.feedbackService.notificarErros((erro as AresErro).violacoes);
    }
  }

  private handleErroNotFound() {
    const router = this.injector.get(Router);
    void router.navigate(['/notfound']);
    return EMPTY;
  }

  private handleErroUnauthorized() {
    const router = this.injector.get(Router);
    void router.navigate(['/sessao-expirada']);
    return EMPTY;
  }

  private handleErroAccessDenied() {
    const router = this.injector.get(Router);
    void router.navigate(['/access-denied']);
    return EMPTY;
  }

  private handleGatewayTimeout(logger: NGXLogger) {
    const erro = { token: 'HTTP ' + HttpStatusCode.GatewayTimeout };
    const error = new HttpErrorResponse({
      status: HttpStatusCode.GatewayTimeout,
      error: erro,
    });
    return this.handleErroInesperado(logger, error);
  }

  private handleErroInesperado(logger: NGXLogger, error: HttpErrorResponse) {
    logger.log(`[Servidor] código retorno: ${error.status}`);
    logger.debug(`[Servidor] erro: ${JSON.stringify(error.error)}`);
    const router = this.injector.get(Router);
    if (error.error instanceof Blob) {
      void error.error.text().then((json) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        router.navigate(['/erro'], { state: JSON.parse(json) })
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      void router.navigate(['/erro'], { state: error.error });
    }

    return EMPTY;
  }
}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};
