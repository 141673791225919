import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CaptchaComponent } from '@app/shared/captcha/captcha.component';
import { CaptchaService } from '@app/shared/captcha/captcha.service';
import { CpfCnpjPipe } from '@app/shared/cpf-cnpj-pipe/cpf-cnpj.pipe';
import { PtStringDateAdapter } from '@app/shared/data-input/data-input.adapter';
import { DataInputComponent } from '@app/shared/data-input/data-input.component';
import { PtDateParserFormatter } from '@app/shared/data-input/data-input.parser-formatter';
import { FeedbackCampoComponent } from '@app/shared/feedback-campo/feedback-campo.component';
import { LazyLoaderComponent } from '@app/shared/lazy-loader/lazy-loader.component';
import { MonthInputComponent } from '@app/shared/month-input/month-input.component';
import { NoDblClickDirective } from '@app/shared/no-dbl-click/no-dbl-click.directive';
import { NumeroResultadosComponent } from '@app/shared/numero-resultados/numero-resultados.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { DataTableModule } from './datatable/DataTableModule';
import { PluralComponent } from './plural.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),

    NgxPermissionsModule.forChild(),
    DataTableModule,
    NgSelectModule,
  ],
  declarations: [
    CaptchaComponent,
    CpfCnpjPipe,
    DataInputComponent,
    FeedbackCampoComponent,
    LazyLoaderComponent,
    MonthInputComponent,
    NoDblClickDirective,
    PluralComponent,
    NumeroResultadosComponent,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: PtDateParserFormatter },
    { provide: NgbDateAdapter, useClass: PtStringDateAdapter },
    CaptchaService,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    NgbModule,
    NgSelectModule,
    NgxMaskModule,
    NgxPermissionsModule,

    DataTableModule,

    CaptchaComponent,
    CpfCnpjPipe,
    DataInputComponent,
    FeedbackCampoComponent,
    LazyLoaderComponent,
    MonthInputComponent,
    NoDblClickDirective,
    PluralComponent,
    NumeroResultadosComponent,
  ],
})
export class SharedModule {}
