<div class="panel">
  <div class="panel-heading">
    <h2 class="panel-title" tabindex="0">Acesso negado</h2>
  </div>

  <div class="panel-body col-12">
    <section>
      <div class="alert alert-danger">
        <p *ngIf="isAcessoBronze">
          Para consultar seus relatórios, você precisa ter Conta gov.br de nível
          prata ou ouro.
        </p>
        <p *ngIf="isAcessoBronze">
          <a
            href="https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br/duvidas-na-conta-gov.br"
            target="_blank">
            Aumente o nível de sua Conta gov.br.
          </a>
        </p>

        <p *ngIf="isColaborador">
          Para consultar os relatórios de sua empresa, é preciso ter certificado
          digital de
          <a
            href="https://faq-login-unico.servicos.gov.br/en/latest/_perguntasdafaq/comocadastrarCNPJnologinunico.html">
            pessoa jurídica vinculado à sua Conta gov.br
          </a>
          . Eles não podem ser acessados por usuário com perfil “Colaborador”.
        </p>

        <p>
          Caso tenha dificuldades de login, você pode pedir os relatórios no
          Fale Conosco do Banco Central, enviando

          <a
            href="https://www.bcb.gov.br/acessoinformacao/faleconosco"
            target="_blank">
            os documentos necessários para pedir relatórios ou informações
            sigilosas.
          </a>
        </p>
      </div>
    </section>
  </div>
</div>
