import { Option } from './model/option';
import {
  faCog,
  faFileAlt,
  faGenderless,
  faGlobe,
  faHeartbeat,
} from '@fortawesome/free-solid-svg-icons';

export function createMenuOption(): Option[] {
  return [
    {
      link: '',
      label: 'Início',
      title: 'Página principal',
      icon: faGlobe,
      permissions: [
        'REGISTRATO_PF',
        'REGISTRATO_PJ',
        'LU_NIVEL_1',
        'LU_NIVEL_2',
        'LU_NIVEL_3',
        'ATENDIMENTO',
        'GESTOR',
        'CONSULTAR_CAMBIO_BCB',
      ],
      subOptions: null,
    },
    {
      label: 'Relatórios',
      link: '',
      title: 'Requisição de relatórios',
      icon: faFileAlt,
      permissions: [
        'REGISTRATO_PF',
        'REGISTRATO_PJ',
        'LU_NIVEL_1',
        'LU_NIVEL_2',
        'LU_NIVEL_3',
        'ATENDIMENTO',
        'CONSULTAR_CAMBIO_BCB',
      ],
      subOptions: [
        {
          link: '/relatorios/scr',
          label: 'SCR',
          title: 'Empréstimos e Financiamentos',
          icon: faGenderless,
          permissions: [
            'REGISTRATO_PF',
            'REGISTRATO_PJ',
            'LU_NIVEL_1',
            'LU_NIVEL_2',
            'LU_NIVEL_3',
            'ATENDIMENTO',
          ],
          subOptions: null,
        },
        {
          link: '/relatorios/ccs',
          label: 'CCS',
          title: 'Contas e Relacionamentos',
          icon: faGenderless,
          permissions: [
            'REGISTRATO_PF',
            'REGISTRATO_PJ',
            'LU_NIVEL_1',
            'LU_NIVEL_2',
            'LU_NIVEL_3',
            'ATENDIMENTO',
          ],
          subOptions: null,
        },
        {
          link: '/relatorios/ccf',
          label: 'CCF',
          title: 'Cheques Sem Fundos',
          icon: faGenderless,
          permissions: [
            'REGISTRATO_PF',
            'REGISTRATO_PJ',
            'LU_NIVEL_1',
            'LU_NIVEL_2',
            'LU_NIVEL_3',
            'ATENDIMENTO',
          ],
          subOptions: null,
        },
        {
          link: '/relatorios/pix',
          label: 'Pix',
          title: 'Chaves Pix',
          icon: faGenderless,
          permissions: [
            'REGISTRATO_PF',
            'REGISTRATO_PJ',
            'LU_NIVEL_1',
            'LU_NIVEL_2',
            'LU_NIVEL_3',
            'ATENDIMENTO',
          ],
          subOptions: null,
        },
        {
          link: '/relatorios/cambio',
          label: 'Câmbio',
          title: 'Câmbio',
          icon: faGenderless,
          permissions: [
            'REGISTRATO_PF',
            'REGISTRATO_PJ',
            'LU_NIVEL_1',
            'LU_NIVEL_2',
            'LU_NIVEL_3',
            'ATENDIMENTO',
            'CONSULTAR_CAMBIO_BCB',
          ],
          subOptions: null,
        },
      ],
    },
    /*
    {
      link: '/publico/autenticidade',
      label: 'Autenticar relatório',
      title: 'Validar autenticidade de relatório',
      icon: 'check-circle',
      permissions: null,
      subOptions: null,
    },
    */
    {
      label: 'Gestão',
      title: 'Gestão',
      link: '',
      icon: faCog,
      permissions: ['GESTOR'],
      subOptions: [
        {
          link: '/gestao/auditoria',
          label: 'Relatórios Gerados',
          title: 'Relatórios Gerados',
          icon: faGenderless,
          permissions: ['GESTOR'],
          subOptions: null,
        },
        {
          label: 'Quantitativos',
          title: 'Relatórios de quantitativos',
          icon: faGenderless,
          link: '',
          permissions: ['GESTOR'],
          subOptions: [
            {
              link: '/gestao/quantitativos/relatorios',
              label: 'Relatórios',
              title: 'Relatórios SCR - CCS - Câmbio',
              icon: faGenderless,
              permissions: ['GESTOR'],
              subOptions: null,
            },
          ],
        },

        {
          link: '/gestao/diagnostico',
          label: 'Diagnóstico',
          title: 'Fazer o diagnóstico dos componentes usados pelo sistema',
          icon: faHeartbeat,
          permissions: ['GESTOR'],
          subOptions: null,
        },
      ],
    },
  ];
}
