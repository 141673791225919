import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { LoginService } from '@app/core/openid/login.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}

  public canActivate(): boolean | UrlTree {
    const logado = this.loginService.getAuthState();
    if (logado) {
      if (this.naoPodeRepresentarNinguem()) {
        return this.router.parseUrl('/access-denied-lu');
      }

      return true;
    } else {
      this.loginService.login();
      return false;
    }
  }

  private naoPodeRepresentarNinguem(): boolean {
    return (
      this.loginService.user.representacoes.find((r) => r.disponivel) ===
      undefined
    );
  }
}
